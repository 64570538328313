<template>
	<div class="fan-details-form">
		<div class="form-header" v-if="showBase && showAddress">
			<span>Basic Information</span>
		</div>
		<div v-if="showBase" class="form-field">
			<input
				id="name"
				v-model="name"
				type="text"
				placeholder="Enter your name"
				class="input-bar"
				@blur="e => validateName(e)"
				:disabled="disabled"
			/>
			<p>
				<span class="danger-msg show-msg">{{ errors.name }}</span>
			</p>
		</div>
		<div v-if="showBase" class="form-field">
			<input
				id="email"
				v-model="email"
				type="email"
				class="input-bar"
				required
				placeholder="Enter your email"
				@blur="e => validateEmail(e)"
				:disabled="disabled"
			/>
			<p>
				<span class="danger-msg show-msg">{{ errors.email }}</span>
				<span class="danger-msg show-msg">{{ warnings.email }}</span>
			</p>
		</div>
		<div v-if="showMessage" class="form-field">
			<textarea
				v-model="note"
				cols="3"
				rows="3"
				type="text"
				class="input-bar"
				placeholder="Message (optional)"
				@blur="onNoteInputChange"
				:disabled="disabled"
			/>
		</div>
		<div class="form-header" v-if="showAddress && questions && questions.length">
			<span>Questions</span>
		</div>
		<div
			v-for="(question, index) in questions"
			:key="`questions-${index}`"
			class="form-field"
			v-show="(question.type === 'phone' && question.enabled !== false) || question.type !== 'phone'"
		>
			<template v-if="question.type === 'phone' && question.enabled !== false && !isCachedAnswerExists(question.content, index)">
				<TelephoneInputLazy
					:phone="getAutofillAnswer(question.content)"
					:phoneCountryCode="getAutofillAnswer('countryCode')"
					:fcpComplete="fcpComplete"
					:debounce="300"
					:disabled="disabled"
					@update-phone="phone => onPhoneInputChange(phone, index)"
				/>
				<p>
					<span class="danger-msg show-msg">{{ questionErrors[index] }}</span>
				</p>
			</template>
			<template v-else-if="question.type === 'text' && !isCachedAnswerExists(question.content, index)">
				<textarea
					:rows="textAreaRows(question.content)"
					@input="setTextareaHeight"
					:id="`input-${index}`"
					v-model="answers[index]"
					:type="question.type"
					:placeholder="question.content"
					class="input-bar"
					@blur="onQuestionInputChange(index)"
					:disabled="disabled"
				/>
				<p>
					<span class="danger-msg show-msg">{{ questionErrors[index] }}</span>
				</p>
			</template>
			<template v-else-if="question.type === 'dropdown' && !isCachedAnswerExists(question.content, index)">
				<label class="form-label">{{ question.content }}</label>
				<label class="form-label">
					<select
						class="input-bar"
						:id="`input-${index}`"
						:class="{ selected: !!answers[index] }"
						v-model="answers[index]"
						@blur="onQuestionInputChange(index)"
						:disabled="disabled"
					>
						<option disabled selected value="">Select an option</option>
						<option v-for="option in question.options" :value="option.value">{{ option.value }}</option>
					</select>
					<svg
						style="pointer-events: none"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 11.95 11"
						xmlns:xlink="http://www.w3.org/1999/xlink"
						class="chevron"
					>
						<path d="M7.05852 9.5L2.94138 5L7.05852 0.5" fill="none" stroke-width="1"></path>
					</svg>
				</label>
				<p>
					<span class="danger-msg show-msg">{{ questionErrors[index] }}</span>
				</p>
			</template>
			<template v-else-if="!isCachedAnswerExists(question.content, index)">
				<template v-if="question.options && question.options.length">
					<label class="form-label">{{ question.content }}</label>
					<label class="input-bar" v-for="(option, optionIndex) in question.options">
						<input
							:id="`input-${index}-${optionIndex}`"
							v-model="answers[index]"
							:type="question.type"
							@blur="onQuestionInputChange(index)"
							:disabled="disabled"
							:value="option.value"
						/>
						{{ option.value }}
					</label>
				</template>
				<p>
					<span class="danger-msg show-msg">{{ questionErrors[index] }}</span>
				</p>
			</template>
		</div>
		<div class="form-header" v-if="showAddress">
			<span>Address</span>
		</div>
		<div class="form-field mt-0" v-show="showAddress && !showManualAddressEdit">
			<div class="input-bar google-address-autcomplete">
				<input
					id="address-auto-complete"
					class="google-address-autcomplete-input"
					ref="autocomplete"
					type="text"
					placeholder="Street Address"
					@blur="e => validateAddress(e)"
					:disabled="addressSelected"
				/>
				<button v-if="addressSelected || showEditButton" @click="editAddress">Edit</button>
			</div>
			<p>
				<span class="danger-msg show-msg">{{ errors.address }}</span>
			</p>
		</div>
		<div class="address-expanded" v-show="showAddress && showManualAddressEdit">
			<div class="form-field mt-0">
				<input
					id="address-auto-complete"
					ref="autocomplete"
					v-model="address.street"
					type="text"
					placeholder="street"
					:disabled="disabled"
					class="input-bar"
					@blur="e => validateAddress(e)"
				/>
				<p>
					<span class="danger-msg show-msg">{{ errors.address }}</span>
				</p>
			</div>
			<div class="form-field d-flex gap">
				<input v-model="address.city" type="text" placeholder="City" :disabled="disabled" class="input-bar" @blur="e => validateAddress(e)" />
				<label class="form-label tax-manual-state mb-0" :key="provice_updated">
					<select
						class="input-bar"
						:disabled="disabled"
						:class="{ selected: !!list_provinces[address.state] }"
						v-model="address.state"
						@blur="e => validateAddress(e)"
					>
						<option disabled selected value="">Select a Provice/State</option>
						<option v-for="option in list_provinces" :value="option.value">{{ option.name }}</option>
					</select>
					<svg
						style="pointer-events: none"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 11.95 11"
						xmlns:xlink="http://www.w3.org/1999/xlink"
						class="chevron"
					>
						<path d="M7.05852 9.5L2.94138 5L7.05852 0.5" fill="none" stroke-width="1"></path>
					</svg>
				</label>
			</div>
			<div class="form-field d-flex gap">
				<label class="form-label tax-manual-country mb-0">
					<select
						class="input-bar"
						:disabled="disabled"
						:class="{ selected: !!list_countries[address.country] }"
						v-model="address.country"
						@blur="e => validateAddress(e)"
					>
						<option disabled selected value="">Select a Country</option>
						<option v-for="option in list_countries" :value="option.value">{{ option.name }}</option>
					</select>
					<svg
						style="pointer-events: none"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 11.95 11"
						xmlns:xlink="http://www.w3.org/1999/xlink"
						class="chevron"
					>
						<path d="M7.05852 9.5L2.94138 5L7.05852 0.5" fill="none" stroke-width="1"></path>
					</svg>
				</label>
				<input
					v-model="address.postal_code"
					type="text"
					placeholder="Postal Code"
					:disabled="disabled"
					class="input-bar"
					@blur="e => validateAddress(e)"
				/>
			</div>
		</div>
	</div>
</template>
<script>
	import iso3166 from 'iso-3166-2'
	import { debounce as _debounce, sortBy } from 'lodash'
	import emailSpellChecker from '@zootools/email-spell-checker'
	import TelephoneInputLazy from './TelephoneInputLazy'
	import { commonDomains } from './utils'

	export default {
		name: 'FanDetailsForm',
		components: {
			TelephoneInputLazy,
		},
		props: {
			fcpComplete: { type: Boolean, default: false },
			showBase: { type: Boolean, default: true },
			showMessage: Boolean,
			questions: { type: Array, default: () => [] },
			cachedQuestionAnswers: { type: Object, default: () => {} },
			disabled: Boolean,
			autofillQuestionAnswers: { type: Object, default: () => {} },
			showAddress: { type: Boolean, default: false },
		},
		emits: ['setFanDetails', 'formIsValid', 'setAnswers', 'setNote', 'setFanDetailsValid'],
		data() {
			return {
				phoneUpdate: 0,
				email: undefined,
				name: undefined,
				number: '',
				phone_number: '',
				note: undefined,
				address: {
					street: '',
					city: '',
					state: '',
					country: '',
					postal_code: '',
				},
				autocomplete: null,
				autoCompleteText: '',
				addressSelected: false,
				showManualAddressEdit: false,
				answers: [],
				errors: {
					name: '',
					email: '',
					phone_number: '',
					note: '',
					address: '',
				},
				warnings: {
					name: '',
					email: '',
					phone_number: '',
					note: '',
				},
				questionErrors: [],
				reg: /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/,
				telOptions: { placeholder: 'Enter your phone number' },
				showEditButton: false,
				list_countries: [],
				list_provinces: [],
				provice_updated: 0,
			}
		},
		computed: {
			phoneIndex() {
				return this.questions.findIndex(f => f.type === 'phone')
			},
			errorCount() {
				return (
					Object.keys(this.errors).reduce((curr, key) => {
						return this.errors[key] ? curr + 1 : curr
					}, 0) + this.questionErrors.filter(q => q).length
				)
			},
			fanDetails() {
				return {
					name: this.name,
					email: this.email,
					phone_number: this.phone_number,
					note: this.note,
					answers: [...this.answers],
					address: this.address,
				}
			},
		},
		beforeMount() {
			this.questionErrors = this.questions.map(() => {
				return ''
			})
			if (this.questions && this.questions.length) {
				this.answers = this.questions.map(question => {
					if (question.type === 'checkbox') return []
					return ''
				})
			}
			this.onNoteInputChange()
		},
		mounted() {
			this.$emit('formIsValid', false)
			this.name = this.getAutofillAnswer('name')
			this.email = this.getAutofillAnswer('email')
			const options = {
				fields: ['address_components', 'geometry', 'icon', 'name'],
				origin: window.location.origin,
				strictBounds: false,
				types: ['address'],
			}
			if (typeof google === 'object' && typeof google.maps === 'object') {
				this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('address-auto-complete'), options)
				this.autocomplete.addListener('place_changed', this.onPlaceChanged)
			}
			else {
				this.editAddress()
			}

			this.list_countries = []
			for (var country in iso3166.data) {
				this.list_countries.push({
					value: country,
					name: iso3166.data[country].name,
				})
			}
		},
		methods: {
			textAreaRows(placeholder) {
				if (placeholder) {
					return Math.ceil(placeholder.length / 50)
				}
				return 1
			},
			setTextareaHeight(event) {
				const target = event.target
				const lineHeight = parseFloat(window?.getComputedStyle(target).getPropertyValue('line-height') || 19)
				const defaultRows = parseInt(target.getAttribute('rows')) + 1
				if (target.scrollHeight > lineHeight * defaultRows) target.style.height = target.scrollHeight + 'px'
			},
			validateName(e, check = false) {
				if (!check) this.errors.name = ''
				if (this.name !== undefined) {
					if (this.name.length === 0) {
						if (!check) this.errors.name = 'Valid name is required'
						return false
					} else {
						this.errors.name = ''
						return true
					}
				} else if (!this.name) {
					if (!check) this.errors.name = 'Name is required'
					return false
				}
			},
			validateEmail(e, check = false) {
				if (!check) this.errors.email = ''
				this.warnings.email = ''
				if (this.email !== undefined && this.email.length >= 1) {
					if (!this.reg.test(String(this.email).toLowerCase())) {
						if (!check) this.errors.email = 'Valid email address is required'
						return false
					} else {
						const suggestedEmail = emailSpellChecker.run({
							email: this.email,
							domains: [...commonDomains, ...emailSpellChecker.POPULAR_DOMAINS],
						})
						if (suggestedEmail) {
							this.warnings.email = `Did you mean ${suggestedEmail.full}?`
							return true
						} else this.warnings.email = ''

						this.errors.email = ''
						return true
					}
				} else if (!this.email) {
					if (!check) this.errors.email = 'Email address is required'
					return false
				}
			},
			validateAddress(e, check = false) {
				this.showEditButton = true // Show edit button to allow manual address edit
				if (!check) this.errors.address = ''
				if (this.showManualAddressEdit) {
					// For manual address, validate 5 fields
					if (
						this.address.street.length === 0 ||
						this.address.city.length === 0 ||
						this.address.country.length === 0 ||
						this.address.postal_code.length === 0
					) {
						if (!check) this.errors.address = 'Valid address is required'
						return false
					} else {
						this.errors.address = ''
						return true
					}
				} else {
					if (this.autoCompleteText !== undefined) {
						if (this.autoCompleteText.length === 0 || this.address.street.length === 0) {
							if (!check) this.errors.address = 'Valid address is required'
							return false
						} else {
							this.errors.address = ''
							return true
						}
					} else if (!this.autoCompleteText) {
						if (!check) this.errors.address = 'Address is required'
						return false
					}
				}
			},
			onPhoneInputChange(e, index, check = false) {
				this.$emit('setAnswers', this.answers)
				if (this.phoneUpdate === 0) {
					this.phoneUpdate = 1 // Ignore initial call for the phone to avoid showing error message
				} else {
					this.questionErrors.splice(index, 1, '')
					if (e.number === '' && this.questions[index].required) {
						if (!check) this.questionErrors.splice(index, 1, this.questions[index].content + ' is required')
					}
				}
				this.number = e.number
				this.phone_number = e.countryCode + e.number
				this.answers[index] = e.number
			},
			onQuestionInputChange(index, check = false) {
				const question = this.questions[index]
				this.$emit('setAnswers', this.answers)
				if (question.required && !this.answers[index]) {
					if (!check) this.questionErrors.splice(index, 1, question.content + ' is required')
					return false
				} else if (question.required && Array.isArray(this.answers[index]) && this.answers[index].length === 0) {
					// Adding comment to force the hashset
					if (!check) this.questionErrors.splice(index, 1, question.content + ' is required')
					return false
				} else if (question.type !== 'phone') {
					// Overwrite message only for other than phone questions
					this.questionErrors.splice(index, 1, '')
					return true
				} else {
					return true
				}
			},
			onNoteInputChange(e) {
				this.$emit('setNote', this.note)
			},
			isFormValid(check = false) {
				let isAnswersValid = true
				this.questions.forEach((q, index) => {
					if (!this.onQuestionInputChange(index, check)) {
						isAnswersValid = false
					}
				})
				let validEmail = true
				let validName = true
				if (this.showBase) {
					validEmail = this.validateEmail({}, check)
					validName = this.validateName({}, check)
				}
				const isValid = isAnswersValid && validEmail && validName && this.errorCount === 0
				if (isValid) {
					// This is needed due to Auto Complete Fields being poppulated without triggering change events
					this.$emit('setFanDetails', this.fanDetails)
					this.$emit('setAnswers', this.answers)
					this.$emit('formIsValid', this.errorCount === 0)
				}
				return isValid
			},
			isCachedAnswerExists(question, index) {
				if (this.cachedQuestionAnswers && this.cachedQuestionAnswers[question]) {
					this.answers[index] = this.cachedQuestionAnswers[question]
					return true
				}
				return false
			},
			getAutofillAnswer(question) {
				if (this.autofillQuestionAnswers && this.autofillQuestionAnswers[question]) {
					return this.autofillQuestionAnswers[question]
				}
				return undefined
			},
			onPlaceChanged() {
				let place = this.autocomplete.getPlace()

				if (!place.geometry) {
					this.autoCompleteText = ''
					return
				}

				if (place.address_components !== undefined) {
					const addressData = this.parseGoogleMapsPlaceData(place)

					this.addressSelected = true
					this.address.city = addressData.city.long
					this.address.state = addressData.state.short
					this.address.postal_code = addressData.postal_code.long
					this.address.country = addressData.country.short
					this.address.street = addressData.street.short
					this.autoCompleteText = `${addressData.street.short}, ${addressData.city.long}, ${addressData.state.short} ${addressData.postal_code.long} ${addressData.country.short}`
				}
				this.validateAddress()
			},

			parseGoogleMapsPlaceData(placeResultData) {
				const address = {
					streetNumber: 0,
					route: null,
					city: null,
					state: null,
					postal_code: null,
					country: null,
				}

				if (placeResultData && placeResultData?.address_components) {
					const addressComponents = placeResultData.address_components

					address.streetNumber = addressComponents.find(a => a.types.includes('street_number'))?.long_name

					address.route = {
						long: addressComponents.find(a => a.types.includes('route'))?.long_name,
						short: addressComponents.find(a => a.types.includes('route'))?.short_name,
					}

					address.city = {
						long: addressComponents.find(a => a.types.includes('locality'))?.long_name,
						short: addressComponents.find(a => a.types.includes('locality'))?.short_name,
					}

					address.state = {
						long: addressComponents.find(a => a.types.includes('administrative_area_level_1'))?.long_name,
						short: addressComponents.find(a => a.types.includes('administrative_area_level_1'))?.short_name,
					}

					address.postal_code = {
						long: addressComponents.find(a => a.types.includes('postal_code'))?.long_name,
						short: addressComponents.find(a => a.types.includes('postal_code'))?.short_name,
					}

					address.country = {
						long: addressComponents.find(a => a.types.includes('country'))?.long_name,
						short: addressComponents.find(a => a.types.includes('country'))?.short_name,
					}

					address.street = {
						long: address.streetNumber ? `${address.streetNumber} ${address.route.long}` : address.route.long,
						short: address.streetNumber ? `${address.streetNumber} ${address.route.short}` : address.route.short,
					}
				}

				return address
			},
			editAddress() {
				this.showManualAddressEdit = true
			},
		},
		watch: {
			questions: {
				deep: true,
				handler(newVal) {
					if (newVal && newVal.length > 0) {
						this.answers = Array(newVal.length).fill('')
					} else {
						this.answers = []
					}
					this.note = ''
					this.onNoteInputChange()
				},
			},
			name: {
				immediate: true,
				handler() {
					this.$emit('setFanDetails', this.fanDetails)
				},
			},
			email: {
				immediate: true,
				handler() {
					this.$emit('setFanDetails', this.fanDetails)
				},
			},
			'address.country': {
				immediate: true,
				deep: true,
				handler(newVal) {
					const country = !newVal ? 'US' : newVal
					const provinces = iso3166.country(country)
					if (provinces) {
						const list_provinces_tmp = []
						for (var province in provinces.sub) {
							list_provinces_tmp.push({
								value: province.split('-')[1],
								name: provinces.sub[province].name,
							})
						}
						this.list_provinces = sortBy(list_provinces_tmp, 'name');
					}
					this.provice_updated++
					this.$emit('setFanDetails', this.fanDetails)
				},
			},
			address: {
				immediate: true,
				deep: true,
				handler() {
					this.$emit('setFanDetails', this.fanDetails)
				},
			},
			errorCount(newval) {
				if (newval === 0) {
					// Update details only if valid
					this.$emit('setFanDetails', this.fanDetails)
					this.$emit('setAnswers', this.answers)
				}
				this.$emit('formIsValid', newval === 0)
			},
		},
	}
</script>
<style scoped lang="scss">
	.tax-manual-country,
	.tax-manual-state {
		width: 49% !important;
	}
</style>
<style lang="scss">
	:focus {
		color: #424770;
	}
	::placeholder {
		color: #9bacc8;
		opacity: 1;
	}
	:focus::placeholder {
		color: #cfd7df;
	}
	.pac-container:after {
		/* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

		background-image: none !important;
		height: 0px;
	}
	.pac-container {
		border: none;
		background: #fff;
		box-shadow: 0px 15px 50px rgba(0, 29, 109, 0.07), inset 0px -5px 10px rgba(0, 0, 0, 0.01);
		border-radius: 10px;
		font-family: 'Inter', sans;
		font-weight: 400;
		font-size: 0.875rem;
		line-height: 1.125rem;
		color: #000;
		padding: 1rem;
		z-index: 2050 !important;

		.pac-item {
			border: none;
			font-family: 'Inter', sans;
			font-weight: 400;
			font-size: 0.875rem;
			line-height: 1.125rem;
			color: var(--stan-text-dark-color);
			height: 40px;
			padding-left: 0.5rem;
			padding-right: 0.5rem;

			border-radius: 8px;
			display: flex;
			align-items: center;
			cursor: pointer;

			.pac-item-query {
				font-size: 0.875rem;
				line-height: 1.125rem;
				color: var(--stan-text-dark-color);
			}

			.pac-icon {
				display: none;
			}
			&:hover {
				background-color: var(--stan-gray-soft-color);
			}
		}
	}
</style>
